import React, { useEffect } from 'react'
import { title, titleDescription } from '../../config/Config'
import logo_new from "../../Assets/new_images/logo_3d.png"
import tree_jar from "../../Assets/new-theme/Bitmap-simple.png"


const Token = () => {

    useEffect(() => {
        document.title = `Token | ${titleDescription}`;
    });


    return (
        <div>
            <div className="token_sec">
                <div className="inner">
                    <div className="container-index">
                        <div className="info_about_it">
                            <h1>{title} Tokenomics</h1>
                            <p> Tokenomics refers to the economic structure behind a cryptocurrency, defining how tokens are distributed, used, and valued within its ecosystem. It encompasses supply mechanisms, token utility, governance, and incentives to ensure long-term growth and sustainability</p>
                        </div>
                    </div>
                </div>

                <section className='Tokenomics Tokenomics-none pt-0'>
                    <div className='__container-ax'>

                         <div className='__outer-tx'>
                              <div className='__outer-tx_tree'>
                                   <img src={tree_jar} alt="tree"/>
                              </div>

                              <div className='__left-top'>
                                   <h5> TGW</h5>
                                   <h4> Token Name</h4>
                              </div>

                              <div className='__left-center'>
                                   <h5> BEP-20</h5>
                                   <h4>standard</h4>
                              </div>

                              <div className='__left-bottom'>
                                   <h5> 0.51 USDT</h5>
                                   <h4> Token Value</h4>
                              </div>

                              <div className='__right-top'>
                                   <h5> 18</h5>
                                   <h4>decimal</h4>
                              </div>


                              <div className='__right-center'>
                                   <h5> 510 Million</h5>
                                   <h4>Total Token Supply</h4>
                              </div>

                              <div className='__right-bottom'>
                                   <h5> September 2024</h5>
                                   <h4>token Launch</h4>
                              </div>
                         </div>


                    </div>
               </section>
            </div>
        </div>
    )
}

export default Token
