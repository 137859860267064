import React from 'react';
import 'react-tabs/style/react-tabs.css';
import { Link, useLocation } from 'react-router-dom';

 interface LinkType {
    name: string;
    path: string;
}

interface SupportTabProps {
    links?: LinkType[];
}

const SupportTab: React.FC<SupportTabProps> = ({ links = [] }) => {

    const location = useLocation();

    return (
        <div className='__subTab'>
            <ul className='nav'>
                {links.map((link, index) => (
                    <li key={index} className='nav-item'>
                        <Link
                            to={link.path}
                            className={location.pathname === link.path ? "nav-link activeLink" : 'nav-link'}>
                            {link.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SupportTab;
