import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { shortTitle, title, titleDescription } from '../config/Config';
import img1 from '../Assets/Image/temp1.png';
import img2 from '../Assets/Image/temp2.png';
import img3 from '../Assets/Image/temp3.png';
import img4 from '../Assets/Image/temp4.png';
import next_sec from '../Assets/new_images/next_section.png'
import AboutImg from '../Assets/new_images/logo_3d.png'
import RoadmapSection from './components/RoadmapSection';
import { Diversity2, FlagCircle, SatelliteAlt } from '@mui/icons-material';


const About = () => {
    const MISSIONDATA = [
        {
            image: img1,
            title: "Eco-conversation efforts",
            para: "Eco-conservation efforts encompass various global initiatives aimed at creating a more sustainable and environmentally friendly planet. These initiatives include reforestation projects, sustainable urban planning, and conservation efforts. By promoting green practices, these initiatives aim."
        },
        {
            image: img2,
            title: "Understanding Carbon Credits",
            para: "Carbon credits are a market-based approach to reducing greenhouse gas emissions. They represent a permit that allows the holder to emit a certain amount of carbon dioxide or other greenhouse gases. Companies and organizations can buy and sell carbon credits to offset their emissions and incentivize the adoption of eco-friendly practices.",
        },
        {
            image: img3,
            title: "The Impact of Global Warming",
            para: "Global warming refers to the long-term increase in Earth's average surface temperature due to human activities, primarily the burning of fossil fuels. This leads to the release of greenhouse gases, such as carbon dioxide and methane, which trap heat in the atmosphere. "
        },
        {
            image: img4,
            title: "The Benefits of Green Energy",
            para: "Green energy refers to energy generated from renewable sources that have a minimal impact on the environment."
        }
    ]

    useEffect(() => {
        document.title = `About | ${titleDescription}`;
        const elements = document.querySelectorAll('.vision_section_content');
        elements.forEach((elem, index) => {
            setTimeout(() => {
                elem.classList.add('animate');
            }, index * 300);
        });
    });



    return (
        <>
            <div>

            </div>
            <div className='about-sx'>

                <section className='about-header-section '>
                    <div className='about-head'>
                        <p>TGW is a groundbreaking initiative focused on reducing carbon emissions and addressing climate change By utilizing blockchain technology.  </p>
                        <h1>We are here to help you on your <span>journey</span>.
                        </h1>
                    </div>
                </section>


                <section className='section-index about-sx_card'>
                    <div className='container-index'>

                        <div className='col-12 heading-sec'>
                            <h3 className='font-family-heading'>{shortTitle} About</h3>
                            <img src={next_sec} alt='divide' className='' style={{ width: "400px" }} />
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 column-outer-sec'>
                                <div className='sec'>
                                    <div className='stap-sec'>
                                       <SatelliteAlt/>
                                    </div>
                                    <h2> Vision</h2>
                                    <p> Our vision is to establish a worldwide
                                        community that actively conserves the environment and reduces carbon emissions
                                        through innovative blockchain solutions.</p>
                                    <p> We strive to foster a greener, more sustainable future by encouraging the
                                        adoption of renewable energy sources and eco-friendly practices.</p>
                                    <div className='glowing-div'></div>
                                </div>

                            </div>

                            <div className='col-lg-4 col-md-6 column-outer-sec'>
                                <div className='sec'>
                                    <div className='stap-sec'>
                                       <Diversity2/>
                                    </div>
                                    <h2> Commitment </h2>
                                    <p>Environmental Conservation : Implementing strategies to significantly
                                        reduce carbon emissions and promote sustainable living.</p>
                                        <p> Community Engagement: Building a strong, engaged community with shared
                                        goals for a sustainable future.</p>
                                    <div className='glowing-div'></div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 column-outer-sec'>
                                <div className='sec'>
                                    <div className='stap-sec'>
                                       <FlagCircle/>
                                    </div>
                                    <h2> {shortTitle} Goal </h2>
                                    <p>Raise Awareness: Increase public knowledge about the benefits of solar energy and tree planting.</p>
                                    <p> Incentivize Green Practices: Encourage the adoption of renewable energy sources and afforestation through a blockchain-based token system.</p>
                                    <div className='glowing-div'></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>



                <section className='about-information'>
                    <div className='about_section'>
                        <div className='image-section'>
                            <img src={AboutImg} alt={title + 'Logo'} />
                        </div>
                        <div className='text-section'>
                            <h3>About {shortTitle}</h3>
                            <p>TGW is a groundbreaking initiative focused on reducing carbon emissions and addressing climate change
                                By utilizing blockchain technology. TGW aims to promote the use of solar energy and tree planting, creating a global network dedicated to environmental conservation and sustainable living.
                            </p>
                        </div>
                    </div>
                </section>



                <section className='vision_section'>
                    <div className='vision_content'>
                        <h3>Sustainability Focus</h3>

                        <div className='vision_topics'>
                            {MISSIONDATA.map((i, indx) => {
                                return (
                                    <div className='vision_section_content' key={indx}>
                                        <div className='text_sec'>
                                            <h4>{i.title}</h4>
                                            <p>{i.para}</p>
                                        </div>
                                        <div>
                                            <img src={i.image} alt='img' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>

                {/* <section className='signup_section'>
                    <div>
                        <h4>The trusted crypto-native finance platform </h4>
                        <p>Create a free crypto account in a minutes</p>
                        <Link to="/register">Sign Up</Link>

                    </div>
                </section> */}

            </div>
        </>
    );
}

export default About;
