import { BurstMode, Insights, People, PictureAsPdf, Policy, RocketLaunch, Screenshot, SettingsSuggest, ShareLocation, SmartToy, SportsEsports, SwapHoriz, VerifiedUser, ViewInAr, VolunteerActivism, Wallet } from "@mui/icons-material";
import { logo } from "../../config/Config";

export const ROADMAPDETAILS = [
    {
        icon: <RocketLaunch />,
        title: "Launch BEP20 Token",
        para: "August 4th week",
        loc: "left",
        year: 2024
    },
    {
        icon: <VolunteerActivism />,
        title: "ICO token Distribution",
        para: "September 1st week ",
        loc: "right",
        year: 2024
    },
    {
        icon: <People/>,
        title: "Community Building Initiatives",
        para: "September 2nd week",
        loc: "left",
        year: 2024
    }, 
    {
        icon: <Screenshot/>,
        title: "BSC Scan Logo Verification",
        para: "September 3rd Week",
        loc: "right",
        year: 2024
    },
    {
        icon: <ShareLocation/>,
        title: "Tokenomics Address Verification",
        para: "September 4th week",
        loc: "left",
        year: 2024
    },
    {
        icon: <Policy/>,
        title: "Security Audit",
        para: "October 2nd week",
        loc: "right",
        year: 2024
    },
    {
        icon: <VerifiedUser/>,
        title: "Update Logo on Trust Wallet and listing on CMC / Coingecko",
        para: "November",
        loc: "left",
        year: 2024
    },
    {
        icon: <img src={logo} alt="logo" width="60px"/>,
        title: "TGW Token Listing in Centralised exchange",
        para: "December",
        loc: "right",
        year: 2024
    },
    {
        icon : <Wallet/>,
        title: "TGW Wallet Launching",
        para: "March",
        loc: "left",
        year: 2025
    },
    {
        icon: <PictureAsPdf/>,
        title: "TGW DEX Launch",
        para: "June",
        loc: "right",
        year: 2025
    },
    {
        icon: <SettingsSuggest/>,
        title: "TGW UTILITIES",
        para: "August",
        loc: "left",
        year: 2025
    },
    {
        icon: <SwapHoriz/>,
        title: "Centralized exchange launch",
        para: "October",
        loc: "right",
        year: 2025
    },
    {
        icon: <SportsEsports/>,
        title: "TGW Gaming",
        para: "December",
        loc: "left",
        year: 2025
    },
    {
        icon: <SmartToy/>,
        title: "TGW A.I Bot",
        para: "January",
        loc: "right",
        year: 2026
    },
    {
        icon: <ViewInAr/>,
        title: "TGW Green Blockchain Launch",
        para: "March",
        loc: "left",
        year: 2026
    },
    {
        icon: <BurstMode/>,
        title: "TGW NFT Marketplace",
        para: "June",
        loc: "right",
        year: 2026
    },
    {
        icon: <Insights/>,
        title: "TGW Multi Blockchain Bridge",
        para: "September",
        loc: "left",
        year: 2026
    },

]

export default ROADMAPDETAILS;