import React from 'react'
import { title } from '../config/Config'

const Footer = () => {
  return (
    <main className='footer-main'>
      <div className="footer">
        <div className="row align-items-center flex-row-reverse ">
          <div className="col-12 text-center">
            <p> Copyright © {(new Date().getFullYear())} {title}. All Rights Reserved. </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Footer