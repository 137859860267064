import React, { useEffect, useState } from "react";
import { shortLogo, title } from "../config/Config";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import trust_wallet from "../Assets/new_images/trust_wallet.svg"
import metamark from "../Assets/new_images/Metamask.png"
import { useWeb3Con } from "../config/Web3ContextProvider";

const HeaderNew = () => {

    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const location = useLocation();
    const { WalletAddress, web3data } = useWeb3Con();




    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 150 && !isHeaderFixed) {
                setIsHeaderFixed(true);
            } else if (scrollPosition <= 150 && isHeaderFixed) {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isHeaderFixed,WalletAddress]);



    return (
        <>


            <div className={"index-header" + `${isHeaderFixed ? ' fixed_' : ''}`}>
                <div className="container-index">
                    <div className="header-area">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="left-area-header">
                                <div className="header-logo">
                                    <Link to="/">
                                        <img src={shortLogo} alt={title + 'Logo'} />
                                    </Link>
                                </div>


                            </div>

                        </div>

                        <ul className="lg__size">
                            <li className="nav-item btn_item">
                                {location.pathname === '/login' ?
                                    <Link className="nav-button" to="/register"> Register</Link>
                                    :
                                    <Link className="nav-button" to="/login"> Login</Link>
                                }
                                <Button className="nav-button" onClick={onOpenModal} disabled={WalletAddress ? true: false}> {WalletAddress ? 'Connected Wallet'  : 'Connect Wallet'}</Button>
                            </li>
                        </ul>

                    </div>

                </div>

            </div>

            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: 'wallet_modal'
                }}>
                <div className="heading">
                    <h2>Connect wallet</h2>
                </div>
                <div className="inner_info">
                    <div className="bot-bot trust_wallet">
                        <img src={trust_wallet} alt="trust_platform" />
                        <div className="bot-name">
                            <h4 className="mb-0">Trust Wallet</h4>
                            <h6 className="mb-0">DApp in app</h6>
                        </div>
                        <a className="stretched-link"></a>
                    </div>
                    <div className="bot-bot metaMask"  onClick={() => {web3data?.connectWallet();onCloseModal()}}>
                        <img src={metamark} alt="trust_platform" />
                        <div className="bot-name">
                            <h4 className="mb-0">MetaMask</h4>
                            <h6 className="mb-0">Desktop / DApp in app</h6>
                        </div>
                        <a className="stretched-link"></a>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default HeaderNew;