import React from 'react'
import gif from "../../Assets/new_images/no_result.gif"
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { ContentPasteSearch } from '@mui/icons-material';

const NoResult = () => {
    return (
        <div>
            <div className="no_result_found_sec">
                <div className='__icon-img'>
                    <ContentPasteSearch/>
                </div>
                <h5>Sorry! No Result Found <SentimentVeryDissatisfiedIcon /></h5>
                <p>We'er sorry what you were looking for. Please try another way</p>
            </div>
        </div>
    )
}

export default NoResult
