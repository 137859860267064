import { Parallax } from 'react-scroll-parallax';
import tree_jar from "../../../Assets/new-theme/Bitmap-simple.png"
import { title } from '../../../config/Config';
import leavesSingle_img from '../../../Assets/new-theme/leaves-single.png';



const Howit = () => {

     return (
          <>
               {/* // <section id="__howit" className={addClass ? "__howit howit-dark" : "__howit "}> */}
               <section className='__howit'>
                    <div className='__container-ax'>
                         <div className='__howit-1'> <img src={leavesSingle_img} alt='icon' /> </div>

                         <div className='__howit-2'>
                               <img src={leavesSingle_img} alt='icon' /> 
                               </div>
                         <div className='row'>
                              <div className='col-12'>
                                   <Parallax speed={10}>

                                        <div className='__heading-howit'>
                                             <h2>What is Crypto &how it works?</h2>
                                             <h6> Cryptocurrency, often referred to as crypto, is a form of digital or virtual currency that uses cryptography for security. Unlike
                                                  traditional currencies issued by governments and central banks, cryptocurrencies operate on decentralized networks based on blockchain technology. Here's a detailed look at how cryptocurrency works.</h6>
                                        </div>
                                   </Parallax>

                              </div>

                              <div className='col-lg-4'>
                                   <Parallax speed={3}>
                                        <div className='card-1 workit'>
                                             <h4> Blockchain Technology</h4>
                                             <p>At the heart of cryptocurrency is blockchain technology.
                                                  A blockchain is a decentralized ledger that records all transactions across a network of computers.
                                                  Each block in the chain contains a list of transactions, and once a block is completed, it is added to the chain in a linear, chronological order.</p>

                                        </div>
                                   </Parallax>
                              </div>

                              <div className='col-lg-4'>
                                   <Parallax speed={0}>
                                        <div className='card-1 workit'>
                                             <h4> Decentralization</h4>
                                             <p>One of the defining features of cryptocurrencies is decentralization. Unlike traditional currencies, which are
                                                  controlled by central banks and governments, cryptocurrencies operate on a decentralized network of computers (nodes). <br />
                                                  This decentralization allows for peer-to-peer transactions without the need for intermediaries like banks.</p>

                                        </div>
                                   </Parallax>
                              </div>

                              <div className='col-lg-4'>
                                   <Parallax speed={0}>
                                        <div className='card-1 workit'>
                                             <h4> Market size</h4>
                                             <p>According to fortune business insight, the global
                                                  crypto market was expected to be $1.758 Trillion by 2027, how ever by the latest figures from coin market cap the market size is already $2.39 Trillion by July 2024. <br />
                                                  The global crypto currency market is expected to grow with 32% from 2024 to 2027</p>

                                        </div>
                                   </Parallax>
                              </div>
                         </div>
                    </div>
               </section>


               <section className='Tokenomics'>
                    <div className='__container-ax'>
                         <div className='__heading_zs'>
                              <h1> {title} Tokenomics</h1>
                              <p>Tokenomics refers to the economic structure behind a cryptocurrency, defining how tokens are distributed, used, and valued within its ecosystem. It encompasses supply mechanisms, token utility, governance, and incentives to ensure long-term growth and sustainability</p>
                         </div>

                         <div className='__outer-tx'>
                              <div className='__outer-tx_tree'>
                                   <img src={tree_jar} alt="tree" />
                              </div>

                              <div className='__left-top'>
                                   <h5> TGW</h5>
                                   <h4> Token Name</h4>
                              </div>

                              <div className='__left-center'>
                                   <h5> BEP-20</h5>
                                   <h4>standard</h4>
                              </div>

                              <div className='__left-bottom'>
                                   <h5> 0.51 USDT</h5>
                                   <h4> Token Value</h4>
                              </div>

                              <div className='__right-top'>
                                   <h5> 18</h5>
                                   <h4>decimal</h4>
                              </div>


                              <div className='__right-center'>
                                   <h5> 510 Million</h5>
                                   <h4>Total Token Supply</h4>
                              </div>

                              <div className='__right-bottom'>
                                   <h5> September 2024</h5>
                                   <h4>token Launch</h4>
                              </div>
                         </div>


                    </div>
               </section>

          </>
     );
}

export default Howit;
