import React, { useState } from 'react';
import _fetch from '../config/api';
import Confirmation_Email_gif from '../Assets/Icon/forgot-con.gif'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { api_url } from '../config/Config';
import toasted from '../config/toast';
import Loader from '../config/Loader';
import { useNavigate, useParams } from 'react-router-dom';


const Forgotconformation = (props: any) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const { token } = useParams();

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleTogglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    const [btnDisable, setbtnDisable] = useState(false);
    const [password, setpassword] = useState();
    const [confirmPassword, setconfirmPassword] = useState();

    const Navigate = useNavigate();

    const submitForm = async (e: any) => {
        setbtnDisable(true)
        e.preventDefault();

        let data = {
            "password": password,
            "passwordConfirm": confirmPassword
        }

        let res = await _fetch(`${api_url}auth/resetPassword/${token}`, "PATCH", data, {})
        if (res?.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", res?.token);
            localStorage.setItem("user", JSON.stringify(res?.data?.user));
            toasted.success(res?.message);
            Navigate("/dashboard");
            toasted.success(res?.message);
            setbtnDisable(false)
        } else {
            toasted.error(res?.message);
            setbtnDisable(false)
        }
    }

    return (
        <div>
            <div className='data_as'>
                <div className='data___outer'>
                    <div className='form__ssd'>

                        <form onSubmit={submitForm}>
                            <img height="150px" className='data__email_img' src={Confirmation_Email_gif} alt="Confirmation-gif" />

                            <h2 className='mb-3 pb-4'>Confirmation email send on your mail</h2>

                            <div className="mb-3 mt-4">
                                <label htmlFor="pwd" className="form-label">Password:</label>
                                <div className='input__passcode'>
                                    <input type={showPassword ? "text" : "password"} className="form-control" id="pwd" placeholder="Enter password" name="pswd" onChange={(e: any) => setpassword(e.target.value)} value={password} />

                                    <button type="button" className='otp__bttons-sx' onClick={handleTogglePassword}>
                                        {showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </button>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="cpwd" className="form-label">Confirm Password:</label>
                                <div className='input__passcode'>
                                    <input type={showPassword2 ? "text" : "password"} className="form-control" id="cpwd" placeholder="Enter Confirm Password" name="pswd" onChange={(e: any) => { setconfirmPassword(e.target.value) }} value={confirmPassword} />

                                    <button type="button" className='otp__bttons-sx' onClick={handleTogglePassword2}>
                                        {showPassword2 ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </button>
                                </div>
                            </div>

                            <button type="submit" className='btn btn__submit' disabled={btnDisable}>
                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && 'Submit '}
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Forgotconformation;
