import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import Table from "react-bootstrap/Table";
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from "../../config/api";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import NoResult from "./NoResult";
import { Info, RefreshOutlined } from "@mui/icons-material";
import userActive from "../../Assets/Image/userIconactive.png"
import userInactive from "../../Assets/Image/userIconinactive.png"
import { useLocation } from "react-router-dom";

const Team = (props: any) => {
  const { t } = useTranslation();

  let location = useLocation();



  const [sponsorData, setSponsorData] = useState({ email: "", user_info: { name: "" } });
  const [levelSearch, setLevelSearch] = useState('');
  const [downloadID, setDownloadID] = useState('');
  const [allUsersDetail, setAllUsersDetail] = useState([]);
  const [searchedType, setSearchType] = useState('level');
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allLevels, setAllLevels] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
  ]);

  const [keyCount, setKeycount] = useState(0);

  const initialDirectTeamTree = {
    name: '',
    package: 0,
    username: '',
    direct_business: 0,
    rank: '',
    directs: [
      {
        directanotherDirects: []
      }
    ]
  };

  const [directTeamTree, setDirectTeamTree] = useState(initialDirectTeamTree);


  useEffect(() => {
    getSponsorDetails();
    document.title = `${props.pageTitle} | ${titleDescription}`;

  }, []);

  useEffect(() => {
    getAllTeamData();
    getAllTeamTree();
  }, [levelSearch, downloadID, page, limit]);

  const getSponsorDetails = async () => {
    let data = await _fetch(`${api_url}sponsor/sponsordetails`, 'GET', {})
    if (data?.status === 'success') {
      setSponsorData(data?.data[0]);
    }
  }

  const getAllTeamData = async () => {
    setLoading(true);
    if (levelSearch != "" || downloadID != "") {
      var searchData = `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
    } else {
      var searchData = "";
    }
    let data = await _fetch(`${api_url}sponsor/getteam?&page=${page}&limit=${limit}${searchData}`, 'GET', {})
    if (data?.status === 'success') {
      setLoading(false);
      setAllUsersDetail(data?.data);
      setTotalRecords(data?.total);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const getAllTeamTree = async () => {
    setLoading(true);
    // if (levelSearch != "" || downloadID != "") {
    //   var searchData = `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
    // } else {
    //   var searchData = "";
    // }
    let data = await _fetch(`${api_url}network/direct-tree`, 'GET', {})

    if (data?.status === 'success') {
      setDirectTeamTree(data?.data?.[0]);
      setLoading(false);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  const toggleClass = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.toggle('show__details');
    }
  };


  const [tabmode, settabmode] = useState(true);

  const tabChanger = () => {
    settabmode(!tabmode);
  }



  return (
    <main>

      <div className="__refreshButton"><button type="button" className="btn" onClick={() => window.location.reload()} ><RefreshOutlined /></button></div>


      <div className='__subTab'>
        <ul className='nav'>
          <li className="nav-item">
            <button type="button" onClick={() => { tabChanger(); }} className={tabmode ? "nav-link activeLink" : "nav-link"}> {t('Structure')}</button>
          </li>

          <li className="nav-item">
            <button type="button" onClick={() => { tabChanger(); }} className={tabmode ? "nav-link" : "nav-link activeLink"}> {t('Direct Tree')}</button>
          </li>
        </ul>
      </div>


      <div className="team-section">
          {/* <div className="card_heading__df">
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("My Sponsor")}</Accordion.Header>
                <Accordion.Body>
                  <p> <AccountCircleRoundedIcon /> <span> {sponsorData?.user_info?.name ? sponsorData?.user_info?.name : "Loading..."}</span> </p>
                  <p> <EmailIcon /> <span> {sponsorData?.email ? sponsorData?.email : "Loading..."}</span> </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div> */}


          <div className="team-outer">
            {
              tabmode ?
                <>

                  <div className="team_inner_header">
                    <h5 className="hg2333">{t("Structure")}</h5> 

                    <form className="serch-form">
                        <input className="form-control" type="text" id="names" placeholder="Search by username" onChange={(e: any) => setDownloadID(e.target.value)} />

                        <select className="form-control h-auto p-auto" onChange={(e: any) => setLevelSearch(e.target.value)} value={levelSearch}>
                          <option value="">All</option>
                          {allLevels?.map((item: any, key: any) => (
                            <option key={key} value={item}> {'Level' + " " + item} </option>
                          ))}
                        </select>
                      </form>
                  </div>

                  <div className="table_section mt-4">
                    <div className="scrolling_table">
                      <div className="scroll_under">
                        <div className="table_responsive">
                          <Table className="support_table all_tables">
                            <thead className='thead_dashboard'>
                              <tr>
                                <th>#</th>
                                <th>{t("Username")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Email")}</th>
                                <th>{t("Package")}</th>

                                <th>{t("Active Status")}</th>

                                <th>{t("Level")}</th>
                                <th>{t("Country")}</th>

                              </tr>
                            </thead>
                            <tbody className='tbody_dashboard'>
                              {allUsersDetail?.length == 0 ? <tr className='no_data_tr'>
                                <td colSpan={8}>
                                  <NoResult />
                                </td>
                              </tr> :
                                <>
                                  {allUsersDetail?.map((item: any, index: any) => {


                                    return (
                                      <>
                                        <tr className="table_data_row">
                                          <td className="table_data">
                                            <div className="pic_text"> {page > 1 ? index + keyCount + 1 : index + 1} </div>
                                          </td>
                                          <td className="table_data">{item?.downlineId}</td>

                                          <td className="table_data">{item?.downlineDetails?.name}</td>
                                          <td className="table_data">{item?.downlineDetails?.email}</td>
                                          <td className="table_data">{item?.downlineDetails?.total_package}</td>

                                          <td className="table_data">{item?.downlineDetails?.active_status == true ? <span className="text-success"> Activate</span> : <span className="text-danger"> Inactive</span>} </td>

                                          <td className="table_data payment_fee">
                                            {item?.levelNumber}
                                          </td>

                                          {item?.downlineDetails?.country ? <td className="table_data payment_fee">
                                            <img src={item?.downlineDetails?.country_flag} alt="img" style={{ width: "20px" }} />
                                            <span className="ms-2">{item?.downlineDetails?.country}</span>
                                          </td> : <td className="table_data payment_fee">
                                            --
                                          </td>}
                                        </tr>

                                      </>
                                    )
                                  })}
                                </>
                              }
                            </tbody>
                          </Table>

                          {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            pageCount={totalRecords / 10}
                            previousLabel="<"
                          />}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="tree__sec">
                    <div className="team_inner_header">
                      <h5 className="hg2333 pt-2 pb-2"> {t("Direct Tree")}</h5>
                    </div>

                    {/* <div className="soon_s d-flex align-items-center justify-content-center">
                      <img src={Coming_Soon} alt="coming_soon" />
                    </div> */}

                    {/* heading__team_tree */}

                    <div className="row team__sec">
                      <div className="col-lg-12">
                        <div className="card mt-2 main-tree">
                          <div className="card-body">

                            <ul className="tree vertical ">
                              <li>
                                <div id="UserAd" className="admin user-main-sec">

                                  <div className="show__details" onClick={() => toggleClass('UserAd')}> <Info /></div>
                                  <div className="admin-img">
                                    <img className="img-sec" src={directTeamTree?.package > 0 ? userActive : userInactive} alt="active user" />
                                  </div>
                                  <p> {directTeamTree?.name !== 'null null' ? directTeamTree?.name : 'No Name'}</p>
                                  <div className="cads">
                                    <p> <span>User ID: </span>   <span> {directTeamTree?.username ? directTeamTree?.username : ''} </span> </p>
                                    <p>   <span>User Name :</span> <span> {directTeamTree?.name !== 'null null' ? directTeamTree?.name : 'No Name'}</span> </p>
                                    <p>  <span>Package Amount :</span> <span> {directTeamTree?.package ? directTeamTree?.package : "0"}</span> </p>
                                    <p>  <span>Total Business :</span> <span> {directTeamTree?.direct_business ? directTeamTree?.direct_business : '0'} </span> </p>
                                  </div>
                                </div>

                                <ul>
                                  {directTeamTree?.directs?.map((item: any, key: any) => {
                                    return (
                                      <>
                                        <li>
                                          <div id="UserA" className="user-main-sec">

                                            <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                            <div className="user">
                                              <div className="user-img">
                                                <img className="img-sec" src={item?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                              </div>
                                            </div>

                                            <div className="user__name">
                                              <div className="user-img">
                                                <p> {item?.name !== 'null null' ? item?.name : 'No Name'} </p>
                                              </div>
                                            </div>

                                            <div className="cads">
                                              <p> <span>User ID: </span>   <span> {item?.referalUsername ? item?.referalUsername : ''} </span> </p>
                                              <p>   <span>User Name :</span> <span> {item?.name !== 'null null' ? item?.name : 'No Name'}</span> </p>
                                              <p>  <span>Package Amount :</span> <span> {item?.package ? item?.package : "0"}</span> </p>
                                              <p>  <span>Total Business :</span> <span> {item?.direct_business ? item?.direct_business : '0'} </span> </p>
                                            </div>
                                          </div>
                                          <ul>
                                            {item?.directanotherDirects?.map((inneritem: any, key: any) => {
                                              return (
                                                <>
                                                  <li>
                                                    <div id="UserB" className="user-main-sec">

                                                      <div className="show__details"> <Info /></div>

                                                      <div className="user">
                                                        <div className="user-img">
                                                          <img className="img-sec" src={inneritem?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                                        </div>
                                                      </div>

                                                      <div className="user__name">
                                                        <div className="user-img">
                                                          <p> {inneritem?.name !== 'null null' ? inneritem?.name : 'No Name'} </p>
                                                        </div>
                                                      </div>

                                                      <div className="cads">
                                                        <p> <span>User ID: </span>   <span> {inneritem?.username ? inneritem?.username : ""} </span> </p>
                                                        <p>   <span>User Name :</span> <span> {inneritem?.name !== 'null null' ? inneritem?.name : 'No Name'}</span> </p>
                                                        <p>  <span>Package Amount :</span> <span> {inneritem?.package ? inneritem?.package : '0'}</span> </p>
                                                        <p>  <span>Total Business :</span> <span> {inneritem?.direct_business ? inneritem?.direct_business + inneritem?.team_business : '0'} </span> </p>
                                                      </div>
                                                    </div>
                                                  </li>
                                                  {/* <li>
                                                    <div id="UserA" className="user-main-sec">

                                                      <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                                      <div className="user">
                                                        <div className="user-img">
                                                          <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                                        </div>
                                                      </div>

                                                      <div className="user__name">
                                                        <div className="user-img">
                                                          <p> Vista ( funddf ) </p>
                                                        </div>
                                                      </div>

                                                      <div className="cads">
                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                      </div>
                                                    </div>
                                                  </li> */}
                                                </>
                                              )
                                            })}
                                          </ul>
                                        </li>
                                        {/* <li>
                                    <div id="UserA" className="user-main-sec">

                                      <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                      <div className="user">
                                        <div className="user-img">
                                          <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                        </div>
                                      </div>

                                      <div className="user__name">
                                        <div className="user-img">
                                          <p> Vista ( User ) </p>
                                        </div>
                                      </div>

                                      <div className="cads">
                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                      </div>
                                    </div>

                                    <ul>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <div id="UserA" className="user-main-sec">

                                      <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                      <div className="user">
                                        <div className="user-img">
                                          <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                        </div>
                                      </div>

                                      <div className="user__name">
                                        <div className="user-img">
                                          <p> Vista ( User ) </p>
                                        </div>
                                      </div>

                                      <div className="cads">
                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                      </div>
                                    </div>
                                    <ul>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>
                                        <ul>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( User ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={'userInactive'} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </li> */}
                                      </>
                                    )
                                  })}
                                </ul>
                              </li>
                            </ul>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }
          </div>
        </div>
    </main>
  );
};

export default Team;
