import React from 'react';
import { logo, shortTitle, title } from '../../../config/Config';
import { Parallax } from 'react-scroll-parallax';
import { AdsClick, Group, ThumbUpAlt } from '@mui/icons-material';
import { Accordion } from 'react-bootstrap';
import __img3d from '../../../Assets/new-theme/focus-1.jpeg';
import __img3d_1 from '../../../Assets/new-theme/focus-2.png';
import leavesSingle_img from '../../../Assets/new-theme/leaves-single.png';

const AboutCom = () => {


     const CretiveCard = [
          {
               id: "card-2",
               icon: <ThumbUpAlt />,
               heading: "Commitment ",
               pragraph: "Environmental Conservation : Implementing strategies to significantly reduce carbon emissions and promote sustainable living.",
               pragraph2: 'Community Engagement: Building a strong, engaged community with shared goals for a sustainable future.',
          },
          {
               id: "card-1",
               icon: <AdsClick />,
               heading: "Vision",
               pragraph: "Our vision is to establish a worldwide community that actively conserves the environment and reduces carbon emissions through innovative blockchain solutions. We strive to foster a greener, more sustainable future by encouraging the adoption of renewable energy sources and eco-friendly practices.",
               center: true
          },
          {
               id: "card-3",
               icon: <Group />,
               heading: "Goal",
               pragraph: "Raise Awareness: Increase public knowledge about the benefits of solar energy and tree planting",
               pragraph2: "Incentivize Green Practices: Encourage the adoption of renewable energy sources and afforestation through a blockchain-based token system.",
          }
     ]
     return (
          <>
               <section className='__about-sec'>
               <div className='__leafce'> <img src={leavesSingle_img} alt='icon'/> </div>

                    <Parallax speed={10}>

                         <div className='__center-about'>
                              <div className='__imgs'>
                                   <img src={logo} alt='logo' />
                              </div>
                              <h3> About TGW</h3>
                              <p> Welcome to {title}, a pioneering project at the intersection of blockchain technology and environmental sustainability. Our mission is to create a greener, more sustainable world through innovative blockchain solutions and community-driven efforts. About {title}</p>
                         </div>
                    </Parallax>


                    <div className='__container-ax'>
                         <div className='row'>
                              {CretiveCard.map((item, index) => (
                                   <div className='col-md-4 card-container' key={index}>
                                        <Parallax speed={index % 2 === 0 ? -10 : 10}>
                                             <div className="creative-card" data-theme={item.id}>
                                                  <div className='__icon'>
                                                       <p>{item.icon}</p>
                                                  </div>
                                                  <h4>{item.heading} </h4>
                                                  <p className='pragraph-txt'>{item.pragraph}</p>
                                                  <p className='pragraph-txt'>{item.pragraph2}</p>
                                             </div>
                                        </Parallax>
                                   </div>
                              ))}
                         </div>
                    </div>

               </section>



               <section className='__focus'>
                    <div className='__container-ax'>
                         <div className='__leafce'> <img src={leavesSingle_img} alt='icon'/> </div>

                         <div className='__leafce2'> <img src={leavesSingle_img} alt='icon'/> </div>

                         <div className='row'>
                              <div className='col-12'>
                                   <Parallax speed={10}>

                                        <div className='heading'>
                                             <h2 className='heading'> {shortTitle} Sustainability Focus</h2>
                                             <p>Join a community of like-minded individuals dedicated to creating a sustainable future</p>
                                        </div>
                                   </Parallax>

                              </div>
                              <div className='col-md-6'>
                                   <Parallax speed={10}>

                                        <div className='__img'>
                                             <Parallax speed={-5}>

                                                  <img className='__1st' src={__img3d} alt="img-Sustainability" />
                                             </Parallax>

                                             <Parallax speed={8}>

                                                  <img className='__2sd' src={__img3d_1} alt="green-Energy" />
                                             </Parallax>
                                        </div>
                                   </Parallax>

                              </div>

                              <div className='col-md-6 '>
                                   <Parallax speed={10}>
                                        <div className='__txt-sec'>
                                             <Accordion defaultActiveKey="0">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Eco-conversation efforts</Accordion.Header>
                                                       <Accordion.Body>
                                                            Eco-conservation efforts encompass various global initiatives aimed at creating a more sustainable and environmentally friendly planet. These initiatives include reforestation projects, sustainable urban planning, and conservation efforts. By promoting green practices, these initiatives aim
                                                            to reduce the environmental impact of human activities and preserve natural resources for future generations.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Understanding Carbon Credits</Accordion.Header>
                                                       <Accordion.Body>
                                                            Carbon credits are a market-based approach to reducing greenhouse gas emissions. They represent a permit that allows the holder to emit a certain amount of carbon dioxide
                                                            or other greenhouse gases. Companies and organizations can buy and sell carbon credits to offset their emissions and
                                                            incentivize the adoption of eco-friendly practices. This system
                                                            encourages businesses to reduce their carbon footprint and invest in sustainable technologies.
                                                       </Accordion.Body>
                                                  </Accordion.Item>

                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>The Impact of Global Warming</Accordion.Header>
                                                       <Accordion.Body>
                                                            Global warming refers to the long-term increase in Earth's average surface temperature due to human activities, primarily the burning of fossil fuels. This leads to the
                                                            release of greenhouse gases, such as carbon dioxide and methane, which trap heat in the atmosphere. The effects of global warming include rising sea levels, more frequent and severe weather events, and disruptions to ecosystems. Addressing global warming requires reducing greenhouse gas emissions and adopting sustainable practices.
                                                       </Accordion.Body>
                                                  </Accordion.Item>

                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>The Benefits of Green Energy</Accordion.Header>
                                                       <Accordion.Body>
                                                            Green energy refers to energy generated from renewable sources that have a minimal impact on the environment.
                                                            Common types of green energy include solar, wind, hydroelectric, and geothermal power. These energy sources
                                                            are sustainable and help reduce reliance on fossil fuels, thereby decreasing greenhouse gas emissions. Green energy projects are essential for combating climate change and promoting a sustainable future.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </Parallax>

                              </div>
                         </div>
                    </div>
               </section>
          </>
     );
}

export default AboutCom;
