import React from 'react';
import Confirmation_gif from '../../Assets/Icon/Usersuccess.gif'
import { Link, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti'
import { title } from '../../config/Config';


const Success = (props: any) => {
    // const { width, height } = useWindowSize()


    const location = useLocation()
    const details = location.state

    return (
        <div>
            <div className='data_as successful__page '>
                <Confetti opacity={0.8}/>
                <div className='data___outer'>
                    <div className='form__ssd'>
                        <img height="200px" className='data__email_img' src={Confirmation_gif} alt="Confirmation-gif" />
                        <h2>Congratulations on successfully registering! </h2>

                        <h5 className='name___user mb-3'>Dear {details},</h5>

                        <p>Thank you for joining our community! Your registration was successful, and we're excited to have you on board.</p>

                        <div className='__userData'>
                            <ul>
                                <li>
                                    <p>Name :</p>
                                    <p> {title}</p>
                                </li>

                                <li>
                                    <p>Phone Number :</p>
                                    <p> 789765456789</p>
                                </li>

                                <li>
                                    <p> Password :</p>
                                    <p>QWDF$#@@</p>
                                </li>
                            </ul>

                            <div className='__btns'>
                                <Link to="/login" className="btn"> Click Here to Login</Link>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default Success;
