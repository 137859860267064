import React, { useEffect } from 'react'
import next_sec from '../Assets/new_images/next_section.png'
import RoadmapSection from './components/RoadmapSection'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { borderRadius } from '@mui/system'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { titleDescription } from '../config/Config'
import Divider from '@mui/material/Divider';
import { title } from '../config/Config'
import { Link } from 'react-router-dom'



const Roadmap = () => {
    useEffect(() => {
        document.title = `Roadmap | ${titleDescription}`;
    });

    const FAQ = [
        {
            id: 1,
            ques: "Our Vision",
            ans: `At ${title}, we envision a future where blockchain technology and environmental sustainability coexist harmoniously. Our goal is to harness the power of blockchain to support and fund green projects, creating a positive impact on the environment while providing value to our community.`
        },
        {
            id: 2,
            ques: "Our Mission",
            ans: "Our mission is to lead the way in integrating blockchain solutions with sustainable practices. By leveraging advanced technology and innovative strategies, we aim to create an ecosystem that supports green initiatives and promotes environmental consciousness."
        },
        {
            id: 3,
            ques: "The STAGW Token",
            ans: "The STAGW token is the cornerstone of our ecosystem. With a total supply of 51 Crore tokens, STAGW is designed to facilitate transactions that support our sustainability goals. Our unique activation plan ensures that funds are allocated efficiently:",
            points: [
                "10% to the Admin Wallet: For efficient project management and operations.",
                "30% to the Insurance Wallet Contract: Providing security and stability for our ecosystem.",
                "30% for Auto Token Buying on PancakeSwap: Enhancing liquidity and market stability.",
                "30% for Auto Liquidity Addition on PancakeSwap: Ensuring seamless and efficient transactions."
            ]
        },
        {
            id: 4,
            ques: "Our Commitment to Sustainability",
            ans: `Sustainability is at the heart of ${title}. We are dedicated to supporting projects that make a tangible difference to the environment. Our blockchain solutions are designed to promote green practices, reduce carbon footprints, and support sustainable development.`
        },
        {
            id: 5,
            ques: `Why Choose ${title}?`,
            ans: "",
            points: [
                "Innovative Solutions: We use cutting-edge blockchain technology to create sustainable solutions.",
                "Transparent and Secure: Our activation plan and tokenomics are designed with transparency and security in mind.",
                "Community Focus: We prioritize the needs and contributions of our community, fostering a collaborative environment.",
                "Environmental Impact: Every transaction and project we support is aimed at creating a positive environmental impact."
            ]
        },
        {
            id: 6,
            ques: "Meet Our Team",
            ans: "ur team is composed of experienced professionals from diverse backgrounds, all united by a shared passion for sustainability and blockchain technology. We are committed to driving innovation and delivering on our promises to create a greener future."
        },
        {
            id: 7,
            ques: "Get Involved",
            ans: "Join us on our journey to create a sustainable future. Here’s how you can get involved:",
            points: [
                "Invest in STAGW: Support our mission by purchasing STAGW tokens.",
                "Participate in Our Community: Engage with us on social media, join discussions, and collaborate on green initiatives.",
                "Stay Updated: Follow our updates and news to stay informed about the latest developments in our project."
            ]
        },
        {
            id: 8,
            ques: "Contact Us",
            ans: `We welcome your questions, feedback, and ideas. Visit our website at ${title} for more information, or reach out to us directly through our contact page. Together, we can make a difference and build a sustainable future.`
        }
    ]

    return (
        <>
            <div className='roadmap_page'>
                <section className='roadmap_header ' >
                    <h1>
                        ROADMAP
                    </h1>
                </section>

                <RoadmapSection />

                {/* <section className='faq_section'>
                            <div className='faq_inner_div'>
                                <h3>Frequently Asked Questions</h3>
                                <div>
                                    {FAQ.map((i) => {
                                        return (
                                            <>
                                                <Accordion key={i.id} sx={{ background: "black", borderRadius: "5px", marginTop: "5px", color: "white", padding: "5px" }}>
                                                    <AccordionSummary
                                                        expandIcon={<ArrowDropDownIcon style={{ color: "white", fontSize: "30px" }} />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Typography sx={{ fontSize: "20px" }}>{i.ques}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <p className='ans_para'>{i.ans}</p>
                                                            {i.points ? (<ul>
                                                                {i.points.map((point, indx) => <li key={indx} className='ans_para'>{point}</li>)}
                                                            </ul>) : ""}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Divider sx={{ background: "white" }} />
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </section> */}
                <div>

                </div>


                <section className='signup_section'>
                    <div>
                        <h4>The trusted crypto-native finance platform </h4>
                        <p>Create a free crypto account in a minutes</p>
                        <Link to="/register">Sign Up</Link>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Roadmap
