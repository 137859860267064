import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import _fetch from "../../config/api";
import { api_url, logo, title } from "../../config/Config";
import toasted from "../../config/toast";
import Loader from "../../config/Loader";
import __img_login from "../../Assets/homepage/loginsec.svg";
import { ArrowDropDownRounded, CheckCircleRounded, ErrorOutlineRounded } from "@mui/icons-material";




const Forgot = (props: any) => {

  const [email, setemail] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);

  const submitForm = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();

    let data = {
      "email": email,
    }

    let res = await _fetch(`${api_url}auth/forgotPassword`, "POST", data, {})
    if (res?.status === "success") {
      toasted.success(res?.message);
      setbtnDisable(false)
    } else {
      toasted.error(res?.message);
      setbtnDisable(false)
    }
  }


  useEffect(() => {
    document.title = props.pageTitle;
  });

  const [hasFocused, setHasFocused] = useState(false);


  const containsAtSymbol = () => {
    return email.includes('@gmail.com');
  };


  const isInputLongEnough = () => {
    return email.trim().length >= 4;
  };

  const handleFocus = () => {
    setHasFocused(true);
  };

  return (
    <>

      <div className="__auths">
        <div className="__authcard">
          <div className="row">

            <div className="col-lg-5 IMg__src_out">
              <div className="IMg__src">
                <div>
                  <div className="__img">
                    <img src={__img_login} alt="img" />

                  </div>
                  <h5> Forgot Password </h5>
                  <p> Enter your personal details </p>
                </div>

                <h6>Don't have a {title} account?  <br /> <Link to="/register">Sign up now</Link></h6>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="__right_">
                <div className="card-header">
                  <Link to="/"> <img src={logo} alt={title + "logo"} /></Link>

                  <button type="button" className="btn"> ENG <ArrowDropDownRounded /> </button>
                </div>

                <div className="card-body">
                  <h5>Sign in to Your Account</h5>
                  <p> Please sign in to enter in {title}</p>

                  <div className="Input_area">

                    <form className="form-start" onSubmit={submitForm}>
                      <div className="__inputGp">
                        <input type="text" id="email" placeholder="Enter Email Address" onChange={(e: any) => setemail(e.target.value)} onFocus={handleFocus} value={email} />

                        {hasFocused && (!isInputLongEnough() || !containsAtSymbol()) ? (
                          <div>
                            <OverlayTrigger
                              key={"left"}
                              placement={"left"}
                              overlay={
                                <Tooltip id="tooltip-left">
                                  <span> Please Enter Your Email Address </span>
                                </Tooltip>
                              }
                            >
                              <span className="__message text-danger"> <ErrorOutlineRounded /> </span>
                            </OverlayTrigger>
                          </div>


                        ) : (

                          hasFocused && isInputLongEnough() && containsAtSymbol() && (
                            <span className="__message text-success"  ><CheckCircleRounded /></span>
                          )
                        )}

                      </div>

                      {hasFocused && (!isInputLongEnough() || !containsAtSymbol()) ? (
                        ""
                      ) : (

                        hasFocused && isInputLongEnough() && containsAtSymbol() && (
                          <div className="btn__s">
                            <button type="submit" className="log-btn" disabled={btnDisable}>
                              {btnDisable === true &&
                                <Loader />
                              }
                              {btnDisable === false && <span className="btnText">Forgot Password</span>}
                            </button>
                          </div>
                        )
                      )}






                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </>

  )
}

export default Forgot