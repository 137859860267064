
import React, { useEffect, useState } from 'react';
import _fetch from '../config/api';
import { api_url, title } from '../config/Config';
import toasted from '../config/toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Confirmation_Email_gif from '../Assets/Icon/Confirmation_pross.gif'
import Confirmation_success from '../Assets/Icon/success.gif'


const ApprovedScreen = (props: any) => {
    const Navigate = useNavigate();
    const { token } = useParams();

    const [timer, setTimer] = useState('00:00');
    const [imageData, setimageData] = useState<string>(Confirmation_Email_gif);

    const [content, setcontent] = useState(false);


    const verification = async () => {
        const data = {
            "token": token,
        };
        let res = await _fetch(`${api_url}auth/verifyAccount`, "POST", data, {});

        if (res?.status === "success") {
            toasted.success(res?.message);
            setimageData(Confirmation_success);
            setcontent(true)

            if (timer === "00:00") {
                var seconds: any = '5';
                var intervalId = window.setInterval(function () {
                    seconds--;

                    // if (seconds > 1) {
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;

                    setTimer(leftData);
                    if (seconds == 0) {
                        Navigate("/login");

                        stop();
                    }
                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
            // localStorage.setItem("auth", 'true');
            // localStorage.setItem("accessToken", res?.token);
            // localStorage.setItem("user", JSON.stringify(res?.data?.user));
            // Navigatex("/dashboard");

        }
    }

    useEffect(() => {
        verification();
    }, [])






    return (
        <div>
            <div className='data_as'>
                <div className='data___outer'>
                    <div className='form__ssd'>

                        <img height="200px" className='data__email_img' src={imageData} alt="Confirmation-gif" />

                        <h2> {content ? 'Please Wait for Verification ' : 'Email Verification Successful'} </h2>

                        {/* {timer !== "00:00" && <h4> Time : <span>{timer}</span></h4> } */}

                        <p> {content ? 'Thank you for verifying your email address. Your account is now confirmed and ready to use.' : ''}</p>

                        <p> {content ? <> If you have any questions or need further assistance, please don't hesitate to contact our support team at <a href='mailto:support@giccapital.uk' target='_blank'> support@giccapital.uk </a> </> : 'Your email is currently being verified. Please wait for a moment while we confirm your email address. This process should only take a few seconds.'}</p>

                        <p className='tq__sec'>{content ? `Thank you for choosing ${title}!` : 'Thank you for your patience.'}</p>

                        <div className='button__group'>
                            {content ? <Link to="/login" className='btn btn__submit'> Login </Link> : ''}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApprovedScreen;


