import React, { useEffect, useState } from 'react'
import { api_url } from '../../config/Config'
import toasted from '../../config/toast';
import _fetch from '../../config/api';
import { ColorRing } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import Loader from "../../config/Loader";
import { AdminPanelSettingsRounded, CopyAll } from '@mui/icons-material';

const Authenticator = () => {
    const [sec2faImg, setsec2faImg] = useState('');
    const [sec2faCode, setsec2faCode] = useState('');
    const [secret, setsecret] = useState('');

    const [timer, settimer] = useState('00:00');
    const [otp2fa, setotp2fa] = useState();
    const { t, i18n } = useTranslation();
    const [btnDisable, setbtnDisable] = useState(false);

    async function security2fa() {
        // toasted.success('Please Wait');

        var d = await _fetch(`${api_url}auth/generateQrCode`, 'GET', {}, {});
        if (d?.status === 'success') {
            setsec2faImg(d.data.qr_url);
            setsec2faCode(d.data.base32);
            setsecret(d.data.textBase);
            if (timer === "00:00") {
                var seconds = '120';
                var intervalId = window.setInterval(function () {
                    seconds--;
                    let minutes = Math.floor(seconds / 60);
                    let extraSeconds = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;
                    settimer(leftData);
                    if (seconds === 0) {
                        security2fa();
                        stop();
                    }
                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
        } else {
            toasted.error(d.message);

        }
    }


    const [removeOtp, setremoveOtp] = useState();
    const submitRemove2Fa = async (e) => {
        e.preventDefault();
        setbtnDisable(true);
        const data = {
            "otp": removeOtp
        }
        var d = await _fetch(`${api_url}auth/deactivateAuth`, 'POST', data, {});
        if (d?.status === 'success') {
            setbtnDisable(false);
            getUserInfo()
            toasted.success(d.message);
        } else {
            setbtnDisable(false);
            toasted.error(d.message);

        }
    }
    const [SecurityType, setSecurityType] = useState('none');
    const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
    async function getUserInfo() {
        var d = await _fetch(`${api_url}user/userInfo`, 'GET', {}, {});
        if (d?.status === 'success') {
            setUserInfo(d?.data?.data);

            setSecurityType(d?.data?.data?.security_type);
            if (d?.data?.data?.security_type == 'none') {
                security2fa()
            }

        }
    }


    const submit2Fa = async (e) => {
        setbtnDisable(true);

        e.preventDefault();
        const data = {
            "base32": sec2faCode,
            "otp": otp2fa

        }
        var d = await _fetch(`${api_url}auth/activate2FA`, 'POST', data, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            //  setOpenQR(false);
            getUserInfo();
            setbtnDisable(false);

        } else {
            setbtnDisable(false);
            toasted.error(d.message);

        }
    }

    useEffect(() => {
        if (timer === "00:00") {

            getUserInfo();
        }
    }, [])


    const copyToClipboard = (address,message) => {

        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toasted.success(message +' copied successfull!');
      }

    return (
        <div className="auth__google">
            <div className="default-block__title --border-bottom">{t("Google Authenticator 2FA Form")}</div>


            {SecurityType == 'none' && <div className="qr__sec ">
                <div className="qr__sec___date">
                    {!sec2faImg &&
                        <><ColorRing
                            visible={true}
                            height="80"
                            width="80"

                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                        /></>

                    }
                    {sec2faImg && <div className='qr___uijjd'> <img src={sec2faImg} alt='qr code' /> </div>}
                </div>

                <div className="input__sec">
                    <form onSubmit={submit2Fa}>
                        <div className="form-group">

                            <label className='top-label mb-1 ms-3'>
                               <span> Secret Key</span>
                                <p>{t("Valid")} : {timer}</p>
                            </label>

                            <div className='inputs_auth'>
                                <input type="text" className="form-control" placeholder="Secret Key" value={secret} />
                                <button type='button' className='btn-copy' onClick={() => {copyToClipboard(secret,'Secret Key')}}> 
                                    <CopyAll/>
                                </button>
                            </div>

                            <label className='mt-3 mb-1 ms-3'>{t("Enter auth code")}</label>

                            <input type="number" className="form-control" placeholder="" onChange={(e) => { setotp2fa(e.target.value) }} value={otp2fa} />
                        </div>

                        <div className="btns__sec_auth">
                            <button type="submit" className="new_button_css_dashboard" disabled={btnDisable}>

                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && `${t("Active")}`}
                            </button>
                        </div>
                    </form>
                </div>

            </div>}



            {/* deactivate  */}

            {SecurityType != 'none' && <div className="qr__sec " >

                <div className="input__sec">
                    <form onSubmit={submitRemove2Fa}>
                        <div className="form-group">
                            <label>
                                <p>{t("Enter auth code")}</p>
                            </label>
                            <input type="number" className="form-control" placeholder="" onChange={(e) => { setremoveOtp(e.target.value) }} value={removeOtp} />
                        </div>

                        <div className="btns__sec_auth">
                            <button type="submit" className="btn btn-danger" disabled={btnDisable}>
                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && `${t("Deactivate")}`}
                            </button>
                        </div>
                    </form>
                </div>

            </div>}
        </div>
    )
}

export default Authenticator