import React from 'react'
import next_sec from '../../Assets/new_images/next_section.png'
import ROADMAPDETAILS from '../../Public/staticData/roadmapdata'

const RoadmapSection = () => {

    return (
        <div className='roadmap_section'>
            {/* <div className='heading_raodmap'>
                <h3>RoadMap</h3>
                <img src={next_sec} alt='divide' className='' style={{ width: "330px" }} />
            </div> */}

            <div className='roadmap_elem'>
                {ROADMAPDETAILS.map((i, indx) => {
                    return (
                        <div className='roadmap_contant_section' key={indx}>
                            {i.loc === "right" ? (<div className='about_rodmap_right'>
                                <h3>{i.year}</h3>
                                <h4>{i.title}</h4>
                                <div className='line_div'></div>
                                <p>{i.para}</p>
                            </div>) : (<div className='blank_div'></div>)}

                            <div className='icon_div'>
                                <div className='about_rodmap_div'><p>{indx + 1}</p></div>
                                {
                                    ROADMAPDETAILS.length !== indx + 1 && <div className='center_div'></div>
                                }

                            </div>
                            {i.loc === "left" ? (<div className='about_rodmap_left'>
                                <h3>{i.year}</h3>
                                <h4>{i.title}</h4>
                                <div className='line_div'></div>
                                <p>{i.para}</p>
                            </div>) : (<div className='blank_div'></div>)}
                        </div>
                    )
                })}
            </div>

            <div className='comingsoon'>
                <p>More Strategies </p>
                <h4>Coming Soon</h4>
            </div>
        </div>
    )
}

export default RoadmapSection;
