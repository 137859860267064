import React, { createContext, useContext, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../src/locales/en/translation.json';
import translationFR from '../src/locales/fr/translation.json';
import translationArabic from '../src/locales/arabic/translation.json';
import translationChinese from '../src/locales/chinese/translation.json';
import translationGerman from '../src/locales/german/translation.json';
import translationHindi from '../src/locales/hindi/translation.json';
import translationIndonesian from '../src/locales/indonesian/translation.json';



const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const resources = {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    arabic:{
      translation: translationArabic
    },
    chinese:{
      translation: translationChinese
    },
    german:{
      translation: translationGerman
    },
    hindi:{
      translation: translationHindi
    },
    indonesian:{
      translation: translationIndonesian
    }
  };

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: selectedLanguage, // Set the default language
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });



  return (
    <LanguageContext.Provider value={{ selectedLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
